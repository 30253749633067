import React, { useEffect, useState } from "react";
import API from "../utils/api.js";
import { shad } from "../utils/global.js";
// import "../assets/scss/pages/home.scss";

export default function Home() {
  const [component, setComponent] = useState(null);
  const [templateData, setTemplateData] = useState({});
  useEffect(() => {
    getLayout();
  }, []);

  const getLayout = async () => {
    let subdomain = null;
    const webHost = window.location.origin;
    if (webHost.includes("dappstarter.io")) {
      subdomain = webHost.split(".")[0];
    } else {
      subdomain = webHost;
    }

    if (subdomain && subdomain.includes("http://")) {
      subdomain = subdomain.replace("http://", "");
    }
    if (subdomain && subdomain.includes("https://")) {
      subdomain = subdomain.replace("https://", "");
    }
    if (!webHost) return;
    if (!subdomain) return;
    const layouts = await API.get(`web/detail/${subdomain}`).catch(() => false);
    if (!layouts) return null;
    let _data = null;
    try {
      _data = layouts.data.data;
    } catch {}

    if (!_data) return null;
    _data.template = "classicsimple";

    for (const key of ["logo", "backgroundImage", "banner"]) {
      if (_data[key]) {
        _data[key] = process.env.REACT_APP_BASE_URL + "/" + _data[key];
      }
    }

    document.title = _data.name + " - $" + _data.ticker;
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.head.appendChild(link);
    }
    link.href = _data.logo;
    document
      .getElementsByTagName("meta")
      .namedItem("description")
      .setAttribute(
        "content",
        _data.about
          ? _data.about
          : _data.description
          ? _data.description
          : _data.name + " webiste. Made with dappstarter.io"
      );
    setTemplateData(_data);
  };

  useEffect(() => {
    getComponent();
  }, [templateData]);

  const getComponent = async () => {
    if (templateData != {} && Object.keys(templateData).length > 0) {
      const Filenames = {
        classicadvance: "ClassicAdvance",
        classicsimple: "ClassicSimple",
      };
      const importedComponent = await import(
        `../Template/${templateData.template}/${
          Filenames[templateData.template]
        }.jsx`
      );
      setComponent(importedComponent);
    }
  };
  const renderTemplate = () => {
    if (!component) return <></>;
    const Component = component.default;
    return <Component data={templateData} />;
  };
  return <>{renderTemplate()}</>;
}
