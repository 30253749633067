import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;
axios.interceptors.request.use((request) => {
  if (localStorage.getItem("jwt")) {
    request.headers.Authorization = `Bearer ${localStorage.getItem("jwt")}`;
  }

  return request;
});

function handleError(error) {
  console.log(error);
  try {
    if (error.status === 401) {
      // localStorage.clear();
      // return (window.location.href = "/login")
    }
    // console.log(error);
    // console.log(error.data);
    var errorMessage = "";
    if (error.data && typeof error.data === "object") {
      errorMessage = error.data.message;
    }

    return errorMessage;
  } catch {
    return "";
  }
}

export function get(path, params = {}) {
  return new Promise(async (resolve, reject) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/${path}`,
      params: params,
    })
      .then((response) => {
        resolve({
          success: true,
          data: response.data,
        });
      })
      .catch((err) => {
        let error = handleError(err.response);

        reject({
          success: false,
          result: error,
        });
      });
  });
}

export function post(path, body = {}) {
  return new Promise(async (resolve, reject) => {
    axios({
      method: "POST",
      url: `${BASE_URL}/${path}`,
      data: body,
    })
      .then((response) => {
        resolve({
          success: true,
          data: response.data,
        });
      })
      .catch((err) => {
        let error = handleError(err.response);
        reject({
          success: false,
          result: error,
        });
      });
  });
}
export function patch(path, body = {}) {
  return new Promise(async (resolve, reject) => {
    axios({
      method: "PATCH",
      url: `${BASE_URL}/${path}`,
      data: body,
    })
      .then((response) => {
        resolve({
          success: true,
          data: response.data,
        });
      })
      .catch((err) => {
        let error = handleError(err.response);

        reject({
          success: false,
          result: error,
        });
      });
  });
}

export function upload(file) {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();
    formData.append("file", file);
    axios({
      method: "POST",
      url: `${BASE_URL}/upload/image`,
      data: formData,
      // headers: {
      //     Authorization: localStorage.getItem("token"),
      // },
    })
      .then((response) => {
        resolve({
          success: true,
          result: response.data.fileUrl,
        });
      })
      .catch((err) => {
        let error = handleError(err.response);
        reject({
          success: false,
          result: error,
        });
      });
  });
}

export function del(path, body = {}) {
  return new Promise(async (resolve, reject) => {
    axios({
      method: "DELETE",
      url: `${BASE_URL}/${path}`,
      data: body,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        resolve({
          success: true,
          result: response.data,
        });
      })
      .catch((err) => {
        let error = handleError(err.response);
        reject({
          success: false,
          result: error,
        });
      });
  });
}

const API = {
  get,
  post,
  patch,
  upload
};

export default API;
